import '../../sass/projectsPage/main.sass'

console.log("App is working!")

//выдвижное меню (.navigation)---------------------------------------------------------------------
let nav = document.getElementById('nav')
let navButtons = document.getElementsByClassName('navigation__button')
let navCanClick = true;

nav.addEventListener('transitionstart', navTransitionStart)

function navTransitionStart(event) {
    console.log('Nav-items transition was started!')
    navCanClick = false;
    console.log(`navCanClick: ${navCanClick}`)
    
    for (let index = 0; index < navButtons.length; index++) {
        navButtons[index].disabled = true
    }
}

nav.addEventListener('transitionend', navTransitionEnd)

function navTransitionEnd(event) {
    console.log('Nav-items transition was ended!')
    navCanClick = true;
    console.log(`navCanClick: ${navCanClick}`)

    for (let index = 0; index < navButtons.length; index++) {
        navButtons[index].disabled = false
    }
}

let headerMenuBtn = document.getElementById("header-menu-btn");

headerMenuBtn.onclick = function() {
    if (nav.classList.contains("navigation_closed")) {
        headerMenuBtn.classList.remove("closed");
        nav.classList.remove("navigation_closed");
        document.body.classList.add("overflow-hidden");
    }
    else {
        headerMenuBtn.classList.add("closed");
        nav.classList.add("navigation_closed");
        document.body.classList.remove("overflow-hidden");
    }
}
//выдвижное меню (.navigation)---------------------------------------------------------------------